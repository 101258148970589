import React from "react";
import SEO from "../components/seo";
import Layout from "../components/layout";
import SectionHeading from "../components/section-heading.component";
import { ContentContainer } from "./index";
import { css } from "@emotion/react";
import { theme } from "twin.macro";
import OrbsSVG from "../assets/orbs.svg";

const HowItWorksPage = () => {
  return (
    <Layout>
      <SEO title="How it works" />
      <div
        css={css`
          &:before {
            position: absolute;
            z-index: 0;
            content: "";
            width: 100%;

            height: ${theme("spacing.16")};
            background: url(${OrbsSVG as any}),
              linear-gradient(114.88deg, #351645 24.57%, #5a4faf 67.22%);
          }

          background: #fff;
          display: flex;
          justify-content: center;
          min-height: 100vh;
          opacity: 1;
          z-index: 1;

          @media (min-width: ${theme("screens.md")}) {
            &:before {
              height: 540px;
            }
          }
        `}
      >
        <div
          css={css`
            z-index: 1;
          `}
        >
          <ContentContainer id="how-it-works" tw="flex flex-col items-center">
            <SectionHeading
              title="How it works"
              subtitle="Welcome to the future of key management"
            />
            Hello?
          </ContentContainer>
        </div>
      </div>
    </Layout>
  );
};

export default HowItWorksPage;
